import { Controller } from "@hotwired/stimulus"
export default class extends Controller {

  static values = { action: String }
  static targets = [
  	'contactForm',
  	'button',
  	'formField',
  	'emailField',
  	'contactTitle',
  	'success'
  ]

  connect() {
    console.log("Contact Us!")
    console.log(this.actionValue)
  }

  createFormAction(event) {
  	event.preventDefault()
  	this.contactFormTarget.action = this.actionValue
  	console.log("Action updated")

  }

  submitForm(event) {
  	event.preventDefault()
  	var emailField = this.emailFieldTarget.value

  	console.log(emailField)
    if( !emailField || !this._validateEmail(emailField) ) { 
        alert("Sorry, your email is invalid")
    } else {
        if(!this.contactTitleTarget.value) {	
            this.contactFormTarget.submit()
            this.successTarget.classList.remove('hidden')
            this.formFieldTargets.forEach((field, index) => {
            	field.value = ""
            })
        } else {
        	alert("Sorry, A problem has occured.")
        	console.log("value is "+this.contactTitleTarget.value)
        }
    }
  }
  _validateEmail($email) {
    var emailReg = /^([\w-\.]+@([\w-]+\.)+[\w-]{2,4})?$/
    console.log("Email is "+emailReg.test( $email ))
    return emailReg.test( $email )
  }
}
